<template>
  <div class="px-4 py-2">
    <div class="py-4 font-bold text-black border-b border-gray-300 title">
    基本信息
    </div>
    <div class="pt-2 content">
        <div class="" v-if="userInfo.avatar">
          <div style="width:70px;height:70px;overflow: hidden;">
            <img
              :src="userInfo.avatar"
              alt=""
              style="width: 100%;height:100%;object-fit:cover"
            />
          </div>
        </div>
      <div class="flex items-center py-2 item">
        <div class="item_left">用户名:</div>
        <div style='font-weight:600' class="" v-if="userInfo.username">
          {{ userInfo.username }}
        </div>
      </div>
      <div class="flex items-center py-2 item">
        <div class="item_left">昵称:</div>
        <div  style='font-weight:600' class="" v-if="userInfo.nickname">
          {{ userInfo.nickname }}
        </div>
        <img  @click="modifyFunc" style='margin-left:10px;' src='../../assets/xiugai.svg'>
      </div>
      <div class="flex items-center py-2 item">
        <div class="item_left"></div>

      </div>
      <div class="w-full py-2 text-right">
        <!-- <a-button type="primary" @click="modifyFunc" style="width:100px">
          修改
        </a-button> -->
      </div>
      <!-- <div class="w-full pt-4 item">
        <div>账号密码</div>
        <div class="flex justify-between w-full px-1 py-2">
          <span>当前密码强度：强</span>
          <span class="text-primary">修改</span>
        </div>
      </div> -->
    </div>
    <!-- <div class="py-4 font-bold text-black border-b border-gray-300 title">
      内部人员管理
    </div>
    <div class="pt-2 content">
      <div class="item">
        <div>账户名称</div>
        <div class="py-2 input_box">
          <a-input size="small" placeholder="巴拉巴拉" />
        </div>
      </div>
      <div class="w-full pt-4 item">
        <div>账号密码</div>
        <div class="flex justify-between w-full px-1 py-2">
          <span>当前密码强度：强</span>
          <span class="text-primary">修改</span>
        </div>
      </div>
    </div> -->
    <a-modal
      title="修改信息"
      :visible="show"
      :confirm-loading="confirmLoading"
      :footer="false"
      @cancel="show = false"
    >
      <div>
        <a-form :form="form">
          <a-form-item
            :label-col="formItemLayout.labelCol"
            :wrapper-col="formItemLayout.wrapperCol"
            label="昵称"
          >
            <a-input
              v-decorator="[
                'nickname',
                {
                  rules: [{ required: true, message: '请输入标签名称' }],
                },
              ]"
              placeholder="请输入昵称"
            />
          </a-form-item>
          <a-form-item
            :label-col="formItemLayout.labelCol"
            :wrapper-col="formItemLayout.wrapperCol"
            label="密码"
          >
            <a-input
              v-decorator="[
                'password',
                {
                  rules: [{}],
                },
              ]"
              placeholder="请输入密码"
            />
          </a-form-item>
          <a-form-item
            :label-col="formTailLayout.labelCol"
            :wrapper-col="formTailLayout.wrapperCol"
          >
            <a-button
              type=""
              @click="myEditFunc"
              size="large"
              style="width:200px"
            >
              编辑
            </a-button>
          </a-form-item>
        </a-form>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { myInfo, myEdit } from "@/api/user";
const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
};
const formTailLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18, offset: 6 },
};
export default {
  data() {
    return {
      userInfo: {},
      show: false,
      formItemLayout,
      formTailLayout,
      form: this.$form.createForm(this, { name: "dynamic_rule" }),
      confirmLoading: false,
    };
  },
  created() {
    this.getMyInfo();
  },
  methods: {
    getMyInfo() {
      const ACCESS_TOKEN = localStorage.getItem("ACCESS_TOKEN")
        ? JSON.parse(localStorage.getItem("ACCESS_TOKEN"))
        : "";
      myInfo({ admin_user_id: ACCESS_TOKEN.userId }).then((res) => {
        console.log(res);
        if (res.code == 200) {
          this.userInfo = Object.assign({}, res.data);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    modifyFunc() {
      this.show = true;
      this.$nextTick(() => {
        this.form.setFieldsValue({
          nickname: this.userInfo.nickname,
        });
      });
    },
    myEditFunc() {
      this.form.validateFields((err) => {
        if (!err) {
          console.log();
          let formData = this.form.getFieldsValue();
          let data = {};
          data.admin_user_id = this.userInfo.admin_user_id;
          data.nickname = formData.nickname;
          formData.password ? (data.password = formData.password) : "";
          myEdit(data).then((res) => {
            if (res.code == 200) {
              this.$message.success("修改成功");
              this.show = false;
              this.getMyInfo();
            } else {
              this.$message.error(res.msg);
            }
          });
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.title {
  font-size: 20px;
}
.input_box {
  width: 432px;
}
.content {
  .item {
    div {
      font-size: 14px;
    }
    span {
      font-size: 14px;
    }
  }
}
.item_left {
  width: 70px;
}
</style>
